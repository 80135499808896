import React from "react";
import logo from "./img/logo-white.svg";

export default function Socket(props) {
  function joinRoom(e) {
    e.preventDefault();
    if (props.username) {
      let clientId = props.clientId;
      props.setUsernameInCookies(props.username);
      props.socket.emit("join", {
        username: props.username,
        room: props.roomId,
        sid: props.socketId,
        clientId: clientId,
      });
      console.log(`Attempting to join Room ${props.roomId}...`);
    }
  }

  return (
    <div className="joiningRoom">
      <div className="header">
        <div className="logo">
          <img src={logo} height="27px" alt="Velocity logo" />
        </div>
        <div
          className={`connectionStatus ${props.isConnected ? "connected" : ""}`}
        ></div>
      </div>
      <form onSubmit={joinRoom}>
        <div className="formDiv">
          <div>
            <h3>Username</h3>
            <input
              type="text"
              name="username"
              value={props.username || ""}
              onChange={(e) => props.setUsernameInCookies(e.target.value)}
            />
          </div>
          <br />
          <div>
            <h3>Room ID</h3>
            <input
              type="text"
              name="roomId"
              value={props.roomId || ""}
              onChange={(e) => props.setRoomId(e.target.value.toUpperCase())}
            />
          </div>
          <br />
          <br />
          <button type="submit">Join Room</button>
        </div>
      </form>
    </div>
  );
}
