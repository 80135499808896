import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "./img/logo-white.svg";

export default function CardSelect(props) {
  let navigate = useNavigate();

  function selectCard(e, cardId) {
    console.log("--> selectCard: ", cardId);
    e.preventDefault();
    if (cardId === props.selectedCard) {
      cardId = -1;
    }
    let payload = {
      clientId: props.clientId,
      sid: props.socketId,
      room: props.roomId,
      action: "select card",
      data: { cardSelected: cardId },
    };
    props.socket.emit("action", payload);
    console.log(`--> Selecting card ${cardId}...`);
    console.log(`    payload: `);
    console.log(payload);
  }

  function leaveRoom(e) {
    console.log("--> Leaving Room");
    props.socket.emit("leave", {
      clientId: props.clientId,
      username: props.username,
      room: props.roomId,
    });
    navigate(-1);
  }

  function renderCards() {
    if (!props.cardSet) {
      return <div>Loading...</div>;
    }
    const cardSetId = props.cardSet;
    const cards = props.cards;

    const cardImgUrl = `https://vel.devpipeline.org/card/${cardSetId}/`;
    return cards.map((cardFile, i) => {
      // const img = require(folder_path + val + ".png");
      if (cardFile === "card-back.png") {
        return null;
      }
      const isSelected = i === props.selectedCard;
      return (
        <div
          className={`${isSelected ? "selectedCard" : ""} individualcards ${
            props.cardsLocked && !isSelected ? "notSelectedCard" : ""
          }`}
          key={i}
        >
          <img
            className="card"
            src={`${cardImgUrl}${cardFile}`}
            key={i}
            alt={`Card ${cardFile}`}
            onClick={(e) => selectCard(e, i)}
            value={i}
          />
        </div>
      );
    });
  }

  return (
    <div className="room">
      <div className="header">
        <div className="logo">
          <img src={logo} height="27px" alt="Velocity logo" />
        </div>
        <div
          className={`connectionStatus ${props.isConnected ? "connected" : ""}`}
        ></div>
      </div>
      <div className="headerTwo">
        <button onClick={leaveRoom}>&lt;</button>
        <p className="roomcode">ROOM: {props.roomId}</p>
        <h1 className="nickname">{props.username}</h1>
      </div>
      <div className="cards">{renderCards()}</div>
    </div>
  );
}
