import React, { useCallback, useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import "./App.css";
import Socket from "./socket";
import CardSelect from "./cardSelect";
import { io } from "socket.io-client";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  let navigate = useNavigate();
  const roomParam = new URLSearchParams(useLocation().search).get("room");

  const [username, setUsername] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const [socketId, setSocketId] = useState("");
  const [roomId, setRoomId] = useState(roomParam);
  const [socket, setSocket] = useState(null);
  const [cardSet, setCardSet] = useState(null);
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(-2);
  const [cardsLocked, setCardsLocked] = useState(false);
  const [gameState, setGameState] = useState("");
  const [clientId, setClientId] = useState("");

  const client_id_cookie = "murta_client_id";
  const username_cookie = "murta_username";
  const room_id_cookie = "murta_room_id";

  const setUsernameInCookies = (clientUsername) => {
    Cookies.set(username_cookie, clientUsername);
    setUsername(clientUsername);
  };

  const setClientIdInCookies = (clientId) => {
    Cookies.set(client_id_cookie, clientId);
    setClientId(clientId);
  };

  const setRoomIdInCookies = (roomId) => {
    console.log("setting murta_room_id: " + roomId);
    Cookies.set(room_id_cookie, roomId);
    setRoomId(roomId);
  };

  const resetState = useCallback(() => {
    setRoomId("");
    setRoomIdInCookies("");
    setCardSet(null);
    setSelectedCard(-2);
    setCardsLocked(false);
    setGameState("");
  }, []);

  useEffect(() => {
    let tempSocket = socket;
    // Get any existing client_id from the Cookies, if it exists.
    if (socket == null) {
      let host = "https://murta.devpipeline.com";

      console.log("host: " + host);
      tempSocket = io(host);

      tempSocket.on("connect", () => {
        console.log("<- Connected: " + tempSocket.id);
        const roomId = roomParam ? roomParam : Cookies.get(room_id_cookie);
        const clientId = Cookies.get(client_id_cookie);
        const username = Cookies.get(username_cookie);
        setSocketId(tempSocket.id);
        setIsConnected(true);
        setRoomId(roomId);
        setClientId(clientId);
        setUsername(username);

        console.log("connecting: roomId: " + roomId);
        if (roomId && username) {
          console.log("calling -> get game state");

          tempSocket.emit("join", {
            username: username,
            room: roomId,
            sid: tempSocket.id,
            clientId: clientId,
          });
        }
      });

      tempSocket.on("disconnect", (reason) => {
        console.log("<- Disconnected... " + reason);
        // if (reason === "io server disconnect") {
        //   socket.connect();
        // }
        setIsConnected(false);
      });

      tempSocket.on("send client id", (clientId) => {
        console.log("send client id");
        let oldClientId = Cookies.get(client_id_cookie);
        if (!oldClientId) {
          setClientIdInCookies(clientId);
          oldClientId = clientId;
        }
        tempSocket.emit("set client id", { clientId: oldClientId });
      });

      tempSocket.on("kicked from room", (data) => {
        resetState();
        navigate("/");
        toast(data.message);
      });

      tempSocket.on("server message", (message) => {
        console.log("<- Message: ", message);
      });

      tempSocket.on("message", (message) => {
        console.log("<- Message: ", message);
      });

      tempSocket.on("card select confirmed", (message) => {
        console.log("<- Card Select Confirmed: ", message);
        const cardId = parseInt(message["cardID"]);
        console.log(
          "---------" + cardId + " " + typeof cardId + "  " + selectedCard
        );
        if (cardId === selectedCard) {
          setSelectedCard(-1);
        } else {
          setSelectedCard(cardId);
        }
      });

      tempSocket.on("room created", (roomName) => {
        console.log("<- Room Created: " + roomName);
        setRoomId(roomName);
      });

      tempSocket.on("error", (data) => {
        toast(data);
      });

      tempSocket.on("room joined", (roomName) => {
        const existingClientId = Cookies.get(client_id_cookie);

        setRoomId(roomName);
        setRoomIdInCookies(roomName);
        setSelectedCard(-1);
        tempSocket.emit("get game state", {
          room: roomName,
          sid: tempSocket.id,
          clientId: existingClientId,
        });

        const payload = {
          clientId: existingClientId,
          sid: tempSocket.id,
          room: roomName,
          action: "get selected card",
        };

        tempSocket.emit("action", payload);

        console.log("<- Room joined: ", roomName);

        navigate("/inroom");
      });

      tempSocket.on("join rejected", (message) => {
        toast(message);
      });

      tempSocket.on("state changed", (game_state) => {
        console.log("<- Game state changed...");
        console.log(game_state);
        if (
          game_state["stateName"] === "select card" &&
          gameState !== "select card"
        ) {
          setSelectedCard(-1);
        }
        if (game_state["cardLock"] === "true") {
          // setSelectedCard(selectedCard);
          setCardsLocked(true);
        } else {
          setCardsLocked(false);
        }
        console.log(`  Setting card set to ${game_state["currentCardSet"]}`);
        setCardSet(game_state["currentCardSet"]["card_set_id"]);
        setCards(game_state["currentCardSet"]["cards"]);
        setGameState(game_state["stateName"]);
      });

      setSocket(tempSocket);
    }
  }, [
    socket,
    username,
    clientId,
    navigate,
    selectedCard,
    gameState,
    resetState,
    roomParam,
  ]);

  const appState = {
    username,
    socketId,
    roomId,
    socket,
    isConnected,
    clientId,
    setUsername,
    setUsernameInCookies,
    setSocketId,
    setRoomId,
  };

  return (
    <div>
      <Routes>
        <Route path="/" element={<Socket {...appState} />} />
        <Route
          path="/inroom"
          element={
            <CardSelect
              {...appState}
              cardSet={cardSet}
              cards={cards}
              setSelectedCard={setSelectedCard}
              selectedCard={selectedCard}
              cardsLocked={cardsLocked}
            />
          }
        />
      </Routes>
      <ToastContainer hideProgressBar theme={"dark"} />
    </div>
  );
}

export default App;
